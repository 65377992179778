

export const data = [
  {
    variantCode: '',
    variantImage: '',
    // sellingPrice: 0,
    purchasePrice: 0,
    quantity: 0,
    // discount: 0,
    // finalSellingPrice: 0,
    isTopSellingProduct: false,
    vol: "",
    offers: [],
    alcohol_percentage: 0,
    isOfferApplied: false,
    status: "active",
    label: "none",
    description: "",
    storePricing: [
      {
        storeId: "",
        sellingPrice: 0,
        discount: 0,
        finalSellingPrice: 0,
      }

    ]
  },
];

export const priceData = [
  {
    sellingPrice: 0,
    discount: 0,
    finalSellingPrice: 0,
  }
]